import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';

export const StyledPage = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  height: 100%;

  &[data-results='true'] {
    height: calc(100% - 2.875rem);
  }

  body[data-mobile='true'] & {
    height: 'auto';
  }
`;

export const Header = styled.div`
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: var(--spacing-3xl);
  padding-right: var(--spacing-lg);

  body[data-mobile='true'] & {
    justify-content: center;
    height: 3.5rem;
    padding: 0;
  }
`;

export const Banner = styled.div`
  min-height: 2.875rem;
  background: var(--banner-info);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  position: sticky;
  z-index: 2;

  body[data-mobile='true'] & {
    min-height: 2.25rem;
    padding: var(--spacing-xs) var(--spacing-md);
    text-align: center;
  }
`;

export const ResultHeader = styled.div`
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: var(--spacing-3xl);
  padding-right: var(--spacing-lg);
  top: 0;

  body[data-mobile='true'] & {
    padding: 0;
    justify-content: center;
    min-height: 3rem;
    border-bottom: 1px solid var(--border-hairline);

    img {
      height: 1rem;
    }
  }
`;

export const SkipButton = styled(RMButton)`
  body[data-mobile='true'] & {
    position: absolute;
    right: 0;
    padding: 0 var(--spacing-md);
  }
`;

export const Logomark = styled.img`
  height: 30px;
`;

export const Progress = styled.div`
  width: 100%;
  min-height: 6px;
  background-color: var(--surface-dim);
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--progress);
    height: 100%;
    transition: width 250ms;
    background-color: var(--primary);
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: var(--spacing-3xl);
  gap: var(--spacing-3xl);
  height: 100%;
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  position: relative;

  body[data-mobile='true'] & {
    padding-top: 0;
    max-width: 100%;
  }
`;

export const BodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xl);
  width: 100%;

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-md);

    /* Add a padding to the bottom the same size as the buttons */
    padding-bottom: 7rem;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const PickerContainer = styled.div`
  padding: 0 var(--spacing-3xl);

  body[data-mobile='true'] & {
    padding: 0;
  }
`;

export const Asset = styled.div`
  height: 100%;
  width: 100%;
  max-height: 302px;

  > img,
  > video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--radius-regular);
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
  max-width: 343px;
  padding-bottom: var(--spacing-xl);

  body[data-mobile='true'] & {
    max-width: 100%;
    padding: var(--spacing-md);
    position: fixed;
    bottom: 0;
    background-color: var(--surface);
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
`;

export const PreviousButton = styled(RMButton)`
  span {
    display: none;
  }
  padding: 0;
  flex: 0 0 48px;
`;

export const LoginLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  background-color: var(--surface);
  position: absolute;
  inset: 0;
  z-index: 2;
`;

export const Video = styled.video`
  &[data-loading='true'] {
    /* This will hide the play button on top of the loader */
    opacity: 0;
  }
`;
