import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import logomarkSrc from '@/@assets/logomark.svg';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { RMWordmark } from '@/components/RMWordmark/RMWordmark';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { QuestionnaireLoading } from '@/modules/questionnaire/components/QuestionnaireLoading/QuestionnaireLoading';
import { QuestionnairePicker } from '@/modules/questionnaire/components/QuestionnairePicker/QuestionnairePicker';
import { QuestionnaireResultsContainer } from '@/modules/questionnaire/containers/QuestionnaireResults.container';
import {
  createQuestionnaireManager,
  getActiveStepState,
  nextStep,
  previousStep,
  QuestionnaireManager,
  setStep,
  toggleOption,
  useActiveStepState,
  useQuestionnaireStep,
} from '@/modules/questionnaire/questionnaire.manager';
import {
  QuestionnaireServicesProvider,
  useQuestionnaireServices,
} from '@/modules/questionnaire/services/questionnaire-services.context';
import {
  getCheckoutBabyHostPath,
  getCheckoutBundlePath,
  getCheckoutPath,
  getQuestionnairePath,
  getSigninPath,
  RementoPage,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useConversionQuestionnaireStepsQuery } from '@/services/cms/conversion-questionnaire';

import {
  Asset,
  Banner,
  Body,
  BodyContent,
  BodyWrapper,
  Footer,
  FooterButtons,
  Header,
  LoadingWrapper,
  LoginLinkWrapper,
  Logomark,
  PickerContainer,
  PreviousButton,
  Progress,
  ResultHeader,
  SkipButton,
  StyledPage,
  TextContainer,
  Video,
} from './QuestionnairePage.styles';

interface InternalQuestionnairePageProps {
  manager: QuestionnaireManager;
}

function InternalQuestionnairePage({ manager }: InternalQuestionnairePageProps) {
  const { questionnaireAnalyticsService, redirectService } = useServices();
  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();

  const user = useUser();
  const activeStepState = useActiveStepState(manager);
  const step = useQuestionnaireStep(manager, activeStepState.name);

  const progress = useMemo(() => {
    const total = manager.steps.length * 2;
    const currentStepIncrement = activeStepState.state === 'answer' ? 1 : 0;
    const current = activeStepState.position * 2 + 1 + currentStepIncrement;
    return (current / total) * 100 + '%';
  }, [activeStepState, manager.steps.length]);

  const title = activeStepState.state === 'question' ? step.questionTitle : step.answer.title;
  const subtitle = activeStepState.state === 'question' ? step.questionSubtitle : step.answer.subtitle;

  const [minLoading, setMinLoading] = useState(false);
  const [assetLoading, setAssetLoading] = useState(false);

  const handleSkip = useCallback(() => {
    questionnaireAnalyticsService.onQuestionnaireSkipped();
    navigate(getCheckoutPath());
  }, [navigate, questionnaireAnalyticsService]);

  const startLoading = useCallback(() => {
    setMinLoading(true);
    setAssetLoading(true);
    setTimeout(() => {
      setMinLoading(false);
    }, 500);
  }, []);

  const cancelLoading = useCallback(() => {
    setMinLoading(false);
    setAssetLoading(false);
  }, []);

  const handleNext = useCallback(() => {
    if (activeStepState.state === 'question') {
      startLoading();
    }

    if (activeStepState.name !== 'goals') {
      nextStep(manager);
      return;
    }

    if (activeStepState.options.length === 1 && activeStepState.options[0] === 'babybook') {
      navigate(getCheckoutBabyHostPath());
      return;
    }

    nextStep(manager);
  }, [activeStepState.name, activeStepState.options, activeStepState.state, manager, navigate, startLoading]);

  const handlePrevious = useCallback(() => {
    if (activeStepState.state === 'question') {
      startLoading();
    }

    previousStep(manager);
  }, [activeStepState.state, manager, startLoading]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath({ backupLocalData: true }));
  }, [navigate, redirectService]);

  useEffect(() => {
    if (activeStepState.state === 'question') {
      cancelLoading();
    }
  }, [activeStepState.state, cancelLoading, startLoading]);

  // Analytics
  useEffect(
    () => {
      if (activeStepState.state === 'question') {
        manager.analytics.onQuestionnaireQuestionArrived(activeStepState.name);
      } else {
        manager.analytics.onQuestionnaireResultArrived(activeStepState.name);
      }
    },
    // Be careful with this dependency array. Only watch for the state and name props
    // to avoid triggering this every time a new option is selected
    [activeStepState.state, activeStepState.name, manager.analytics],
  );

  return (
    <>
      {!minLoading && activeStepState.state === 'answer' && step.answer.type === 'results' && (
        <Banner>
          <Link to={getCheckoutBundlePath()}>
            <RMText type="sans" size="s" color="on-surface-primary" align="center">
              Buy one, get one 50% off for a limited time only. <b>SHOP NOW</b>
            </RMText>
          </Link>
        </Banner>
      )}

      <StyledPage data-results={activeStepState.state === 'answer' && step.answer.type === 'results'}>
        {activeStepState.state === 'answer' && step.answer.type === 'results' ? (
          <ResultHeader>
            <RMWordmark destination="marketing" />
          </ResultHeader>
        ) : (
          <Header>
            {isMobile ? <Logomark src={logomarkSrc} /> : <RMWordmark destination="marketing" />}
            <SkipButton background="transparent" rightIcon={faChevronRight} onClick={handleSkip}>
              {isMobile ? 'Skip' : 'Skip to checkout'}
            </SkipButton>
          </Header>
        )}
        {activeStepState.state === 'answer' && step.answer.type === 'results' ? (
          <QuestionnaireResultsContainer manager={manager} />
        ) : (
          <Body>
            <BodyWrapper>
              {activeStepState.state === 'answer' && step.answer.type !== 'results' && (minLoading || assetLoading) && (
                <LoadingWrapper>
                  <QuestionnaireLoading />
                </LoadingWrapper>
              )}
              <Progress style={{ '--progress': progress }} />
              <BodyContent hidden={minLoading || assetLoading}>
                <TextContainer>
                  <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary" align="center">
                    {title}
                  </RMText>
                  {subtitle != null && subtitle.length > 0 && (
                    <RMText type="sans" size="s" color="on-surface-primary" align="center">
                      {subtitle}
                    </RMText>
                  )}
                </TextContainer>
                {activeStepState.state === 'question' && (
                  <PickerContainer>
                    <QuestionnairePicker.Root>
                      {step.questionOptions.map((option) => (
                        <QuestionnairePicker.Item
                          key={option.name}
                          label={option.label}
                          value={option.name}
                          selected={activeStepState.options.includes(option.name)}
                          square={step.questionOptionsType === 'checkbox'}
                          onClick={() => toggleOption(manager, option.name)}
                        />
                      ))}
                    </QuestionnairePicker.Root>
                  </PickerContainer>
                )}
                {activeStepState.state === 'answer' && step.answer.type === 'default' && (
                  <Asset>
                    {step.answer.asset.mimeType.includes('image') ? (
                      <img src={step.answer.asset.url} onLoad={() => setAssetLoading(false)} alt="Onboarding image" />
                    ) : (
                      <Video
                        src={step.answer.asset.url + '#t=0.5'}
                        data-loading={assetLoading || minLoading}
                        onLoadedMetadata={() => setAssetLoading(false)}
                        autoPlay={step.answer.asset.assetPlaybackMode === 'autoplay'}
                        loop
                        playsInline
                        controls={step.answer.asset.assetPlaybackMode === 'controls'}
                        muted={step.answer.asset.assetPlaybackMode === 'autoplay'}
                        disablePictureInPicture
                      />
                    )}
                  </Asset>
                )}
              </BodyContent>
              {!(minLoading || assetLoading) && (
                <Footer>
                  <FooterButtons>
                    {(activeStepState.position > 0 || activeStepState.state === 'answer') && (
                      <PreviousButton leftIcon={faChevronLeft} onClick={handlePrevious} />
                    )}
                    <RMButton background="primary" onClick={handleNext} fullWidth>
                      Next
                    </RMButton>
                  </FooterButtons>

                  {activeStepState.position === 0 && user == null && (
                    <LoginLinkWrapper>
                      <RMText type="sans" size="xs" color="on-surface-secondary">
                        Already have an account? Log in{' '}
                        <RMText
                          type="sans"
                          size="xs"
                          color="on-surface-secondary"
                          cursor="pointer"
                          underline
                          onClick={handleSignIn}
                        >
                          here
                        </RMText>
                        .
                      </RMText>
                    </LoginLinkWrapper>
                  )}
                </Footer>
              )}
            </BodyWrapper>
          </Body>
        )}
      </StyledPage>
    </>
  );
}

interface InternalQuestionnaireRootPageProps {
  onInitializeManager: (manager: QuestionnaireManager) => void;
}

function InternalQuestionnaireRootPage({ onInitializeManager }: InternalQuestionnaireRootPageProps) {
  // Services
  const navigate = useNavigate();
  const { questionnaireAnalyticsService } = useServices();
  const { questionnaireStateRepository } = useQuestionnaireServices();

  // Queries
  const questionnaireStepsQuery = useConversionQuestionnaireStepsQuery();

  // Initialize the manager
  // Use this ref to avoid creating two managers during development.
  const initializedRef = useRef(false);
  useEffect(() => {
    if (questionnaireStepsQuery.data == null || initializedRef.current == true) {
      return;
    }
    initializedRef.current = true;
    questionnaireAnalyticsService.onQuestionnaireArrived();

    const manager = createQuestionnaireManager(
      navigate,
      questionnaireStateRepository,
      questionnaireAnalyticsService,
      questionnaireStepsQuery.data,
    );
    const activeState = getActiveStepState(manager);
    onInitializeManager(manager);

    // Navigate to the step page
    // This should always replace the current url to avoid back issues.
    navigate(getQuestionnairePath(activeState.name, activeState.state));
  }, [
    navigate,
    onInitializeManager,
    questionnaireAnalyticsService,
    questionnaireStateRepository,
    questionnaireStepsQuery.data,
  ]);

  return <PageLoader />;
}

export function QuestionnairePage() {
  const params = useParams();
  const [manager, setManager] = useState<QuestionnaireManager | null>(null);

  // Update the current step when using the back/forward button in the browser
  useEffect(() => {
    if (manager == null) {
      return;
    }

    const name = params.name;
    const state = params.state;

    if (name != null && (state === 'answer' || state === 'question')) {
      setStep(manager, name, state);
    }
  }, [manager, params]);

  return (
    <RementoPage type="default">
      <QuestionnaireServicesProvider>
        {manager != null ? (
          <InternalQuestionnairePage manager={manager} />
        ) : (
          <InternalQuestionnaireRootPage onInitializeManager={setManager} />
        )}
      </QuestionnaireServicesProvider>
    </RementoPage>
  );
}
